// src/users.js
const users = [
    {
      email: "sanyelisgil.rah@gmail.com",
      password: "SanyelisRah2024.",
      userName: "rah.administrador",
      type: "Admin" || "Gerente"
    },
    {
      email: "david@econecta.io",
      password: "Econecta2025.",
      userName: "eco.administrador",
      type: "Admin" || "Gerente"
    }
  ];
  
  export default users;